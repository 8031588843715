import React from "react";
import { graphql } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import Helmet from "react-helmet";
import { Box } from "rebass";
import { getSrc } from "gatsby-plugin-image";

import Layout from "../components/layout";
import { HTMLContent } from "../components/Content";
import {
  AnimationUp,
  HideOnTablet,
  HideOnPhoneDesktop,
  Container,
  HeroSideImage,
  H1,
  H3,
  H4,
  TextBody1,
} from "../ui";

import { randomNumber } from "../utils/helpers";

export const aboutPageQuery = graphql`
  query($id: String!) {
    image: file(relativePath: { eq: "punch.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "interview-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 180)
          id
          fields {
            slug
          }
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            templateKey
            description
            color
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;

const AboutPage = ({ data, location }) => {
  const {
    markdownRemark: post,
    allMarkdownRemark: { edges: posts },
  } = data;

  return (
    <Layout location={location} interview={posts[randomNumber(posts.length)]}>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={data.image}
      />
    </Layout>
  );
};

const AboutPageTemplate = ({ image, title }) => {
  const url = "https://gymlovers.pt";
  const description =
    "O Gymlovers é uma revista digital que faz entrevistas a pessoas em boa forma física e dá a conhecer o seu dia-a-dia, hábitos de treino e alimentares e alguns pequenos segredos que os ajudaram a atingir uma excelente forma física.";
  return (
    <div>
      <Helmet>
        <title>Sobre nós | Gymlovers</title>
        <meta name="description" content={description} />

        <meta itemprop="name" content="Sobre nós | Gymlovers" />
        <meta itemprop="description" content={description} />
        <meta itemprop="image" content={`${url}${getSrc(image)}`} />

        <meta property="og:url" content={`${url}/sobre/`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sobre nós | Gymlovers" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${url}${getSrc(image)}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sobre nós | Gymlovers" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${url}${getSrc(image)}`} />
      </Helmet>
      <HeroSideImage image={image}>
        <AnimationUp seconds={["1s", "2s"]}>
          <H1 my={[3, 2, 2, 2, 4]}>{title}</H1>
        </AnimationUp>
        <AnimationUp seconds={["1.5s", "2s"]}>
          <TextBody1 mb={[2, 3]}>
            O Gymlovers é uma revista digital que faz entrevistas a pessoas em
            boa forma física e dá a conhecer o seu dia-a-dia, hábitos de treino
            e alimentares e alguns pequenos segredos que os ajudaram a atingir
            uma excelente forma física.
          </TextBody1>
          <TextBody1 mb={[2, 3]}>
            A equipa por de trás do Gymlovers é a criadora do{" "}
            <a href="https://gymious.pt/" className="Interview-link">
              Gymious,{" "}
            </a>
            a plataforma que te ajuda a escolher o ginásio ideal para ti.
          </TextBody1>

          <HideOnTablet>
            <Box my={4}>
              <H4 my={3}>Contactos</H4>
              <TextBody1 mb={[2, 3]}>
                A melhor maneira de nos contactar é através do email,{" "}
                <a href="mailto:info@gymlovers.pt" className="Interview-link">
                  diogo@gymlovers.pt
                </a>
                . Também nos podes seguir através do nosso instagram{" "}
                <OutboundLink
                  rel="noreferrer"
                  href="https://www.instagram.com/gymloverspt/"
                  target="_blank"
                  className="Interview-link"
                >
                  @gymloverspt
                </OutboundLink>
                .
              </TextBody1>
            </Box>
          </HideOnTablet>
        </AnimationUp>
      </HeroSideImage>
      <HideOnPhoneDesktop>
        <Container mb={6}>
          <H3>Contactos</H3>
          <TextBody1 mb={[2, 3]}>
            A melhor maneira de nos contactar é através do email{" "}
            <a href="mailto:info@gymlovers.pt" className="Interview-link">
              info@gymlovers.pt
            </a>
            . Também nos podes seguir no{" "}
            <OutboundLink
              rel="noreferrer"
              href="https://www.instagram.com/gymloverspt/"
              target="_blank"
              className="Interview-link"
            >
              @gymloverspt
            </OutboundLink>
            .
          </TextBody1>
        </Container>
      </HideOnPhoneDesktop>
    </div>
  );
};

export default AboutPage;
